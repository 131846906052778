<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <base-card
      :height="value.prominent ? 450 : 350"
      color="lighten-1"
      light
      :href="value.href"
      style="border: 1px solid black"
    >
      <v-img
        :src="require(`@/assets/articles/${value.hero}`)"
        height="100%"
        contain
      >
        <v-row
          v-if="!value.prominent"
          class="fill-height text-right ma-0"
        >
          <v-col cols="12">
            <v-chip
              label
              class="mx-0 mb-2 text-uppercase"
              color="grey darken-3"
              text-color="white"
              small
              @click.stop=""
            >
              {{ value.category }}
            </v-chip>

            <h3 class="title mb-2">
              {{ value.title }}
            </h3>

            <div class="caption">
              {{ value.author }}
            </div>
          </v-col>

          <v-col align-self="end">
            <p class="font-weight-light">
              <span>
                <a
                  style="text-decoration: none; color: black"
                  :href="value.href1"
                  target="_blank"
                >
                {{ value.value3 }}
                </a>
              </span>
              <br>
              <span>
                <a
                  style="text-decoration: none; color: black"
                  :href="value.href1"
                  target="_blank"
                >
                {{ value.value2 }}
                </a>
              </span>
              <br>
              <span>
                <a
                  style="text-decoration: none; color: black"
                  :href="value.href1"
                  target="_blank"
                >
                {{ value.value1 }}
                </a>
              </span>
            </p>
          </v-col>
        </v-row>
      </v-img>
    </base-card>
  </v-col>
</template>

<script>
  export default {
    name: 'FeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
</style>
